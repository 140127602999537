export default [
    {
        path: '/accept-invite',
        name: 'organization/acceptInvite',
        meta: {
            layout: 'empty',
            auth: false,
        },
        component: () => import('../pages/organization/AcceptInvite.vue'),
    },
    {
        path: '/organization/settings',
        name: 'organization/settings',
        meta: {
            layout: 'default',
            breadcrumb: [{ label: 'Settings' }],
        },
        component: () => import('../pages/organization/Settings.vue'),
    },
    {
        path: '/organization/login-provider-mismatch',
        name: 'organization/login-provider-mismatch',
        meta: {
            layout: 'default',
            hideMenu: true
        },
        component: () => import('../pages/organization/ProviderMismatch.vue'),
    }
];
