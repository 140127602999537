import api from './api';

const authenticateUser = async () => {
    const client = await api.getClient();
    return await api.handleSuccess(await client.AuthenticateUser());
};

const updateProvider = async (provider) => {
    const client = await api.getClient();
    return await api.handleSuccess(await client.UpdateProvider(null, { provider }));
};

const getOrganizations = async () => {
    const client = await api.getClient();
    return await api.handleSuccess(await client.GetAuthenticatedUserOrganizations());
};

const sendPasswordResetEmail = async (email) => {
    const client = await api.getClient();
    return await api.handleSuccess(await client.SendResetPasswordEmail(null, { email }));
};

const resetPassword = async (email, code, password) => {
    const client = await api.getClient();
    return await api.handleSuccess(await client.ResetPassword(null, { email, code, password }));
};

const acceptEula = async () => {
    const client = await api.getClient();
    return await api.handleSuccess(await client.AcceptEula());
};

const verifyInviteToken = async (email, token) => {
    const client = await api.getClient();
    return await api.handleSuccess(await client.VerifyInviteToken(null, { email, token}));
}

const checkIsGranted = async (scope) => {
    const client = await api.getClient();
    return await api.handleSuccess(await client.CheckIsGranted({ scope }));
}

const deleteAuth = async () => {
    const client = await api.getClient();
    return await api.handleSuccess(await client.DeleteAuth());
}

export default {
    authenticateUser,
    updateProvider,
    sendPasswordResetEmail,
    resetPassword,
    getOrganizations,
    acceptEula,
    verifyInviteToken,
    checkIsGranted,
    deleteAuth
};
