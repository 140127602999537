export default [
    {
        path: '/logout',
        name: 'auth/logout',
        meta: {
            layout: 'auth',
            auth: false
        },
        component: () => import('../pages/auth/Logout.vue'),
    },
    {
        path: '/login',
        name: 'auth/login',
        meta: {
            layout: 'auth',
            auth: false,
        },
        component: () => import('../pages/auth/Login.vue'),
    },
    {
        path: '/login/email',
        name: 'auth/login-email',
        meta: {
            layout: 'auth',
            backTitle: 'Back to login options',
            backRoute: { name: 'auth/login' },
            auth: false,
        },
        component: () => import('../pages/auth/LoginEmail.vue'),
    },
    // a route for dev environments to simulate provider logins.
    {
        path: '/login/auth-token',
        name: 'auth/auth-token',
        meta: {
            layout: 'auth',
            backTitle: 'Back to login options',
            backRoute: { name: 'auth/login' },
            auth: false,
        },
        component: () => import('../pages/auth/LoginAuthToken.vue'),
    },
    {
        path: '/create-account',
        name: 'auth/create',
        meta: {
            title: 'SC//Fleet Manager Account Creation Options',
            layout: 'auth',
            auth: false,
        },
        component: () => import('../pages/auth/CreateAccount.vue'),
    },
    {
        path: '/invite-only',
        name: 'auth/invite-only',
        meta: {
            layout: 'auth',
            auth: false,
        },
        component: () => import('../pages/auth/InviteOnly.vue'),
    },
    {
        path: '/recover-password',
        name: 'auth/recover',
        meta: {
            layout: 'auth',
            title: 'Recover Password',
            backTitle: 'Back to login',
            backRoute: { name: 'auth/login' },
            auth: false,
        },
        component: () => import('../pages/auth/ForgotPassword.vue'),
    },
    {
        path: '/reset-password',
        name: 'auth/reset',
        meta: {
            layout: 'auth',
            auth: false,
            title: 'Reset Password for Fleet Manager',
            backTitle: 'Back to login',
            backRoute: { name: 'auth/login' },
        },
        component: () => import('../pages/auth/ResetPassword.vue'),
    },
    {
        path: '/change-provider',
        name: 'auth/change-provider',
        meta: {
            layout: 'auth',
            auth: false,
            title: 'Change Login Provider',
            backTitle: 'Back to login',
            backRoute: { name: 'auth/login' },
        },
        component: () => import('../pages/auth/ChangeProvider.vue'),
    }
];
