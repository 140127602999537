<template>
    <div>
        <Offline />
        <component :is="layout">
            <router-view />
        </component>
        <Toast group="messages" />
        <SitewideMessage />
        <SCConfirm></SCConfirm>
        <ClusterUpdatesAvailableModal />
        <ClusterUpdatesStatusModal />
    </div>
</template>
<script>
import SitewideMessage from '@/components/app/SitewideMessage.vue';
import ClusterUpdatesAvailableModal from '@/components/cluster/UpdatesAvailableModal.vue';
import Offline from '@/components/shared/offline/Offline.vue';
import ClusterUpdatesStatusModal from '@/components/cluster/UpdateStatusModal.vue';
import EventBus from '@/layouts/slots/event-bus';
import { SCConfirm } from '@scale-computing/scale-design-framework';


export default {
    components: {
      SitewideMessage,
      ClusterUpdatesAvailableModal,
      ClusterUpdatesStatusModal,
      SCConfirm,
      Offline
  },
  mounted() {
      // handle redirect.
      EventBus.on('redirect-unauthorized', () => {
        this.$store.app.authorized = false;
      });
      EventBus.on('redirect-login-provider-mismatch', () => {
        this.$router.push({ name: 'organization/login-provider-mismatch' });
      });
      EventBus.on('redirect-login', () => {
          this.$router.push({ name: 'auth/login' });
      });
  },
  computed: {
      layout() {
          return 'layout-' + (this.$route.meta.layout || 'empty');
      }
  },
  methods: {},
};
</script>
