<template>
    <div :class="[containerClass, $route.name]" :data-theme="colorScheme" @click="onDocumentClick($event)">
        <div v-if="organization.deletedAt" class="p-text-center org-removed-message">This organization has been removed. Contact customer service to restore.</div>

        <div class="layout-content-wrapper">
            <AppTopBar
                :layoutMode="layoutMode"
                :topbarTheme="topbarTheme"
                :menuActive="menuActive"
                :mobileMenuActive="staticMenuMobileActive"
                :sidebarActive="sidebarActive"
                :sidebarStatic="sidebarStatic"
                :topbarUserMenuActive="topbarUserMenuActive"
                :topbarUserMenuClick="topbarUserMenuClick"
                :topbarHelpMenuActive="topbarHelpMenuActive"
                :topbarHelpMenuClick="topbarHelpMenuClick"
                :searchActive="searchActive"
                :searchClick="searchClick"
                :topbarItemClick="topbarItemClick"
                :activeTopbarItem="activeTopbarItem"
                @menu-click="onMenuClick"
                @menuitem-click="onMenuItemClick"
                @root-menuitem-click="onRootMenuItemClick"
                @menu-button-click="onMenuButtonClick"
                @right-menubutton-click="onRightMenuButtonClick"
                @toggle-menu="onToggleMenu"
                @topbar-usermenu-click="onTopbarUserMenuClick"
                @topbar-helpmenu-click="onTopbarHelpMenuClick"
                @sidebar-mouse-leave="onSidebarMouseLeave"
                @sidebar-mouse-over="onSidebarMouseOver"
                @topbar-search-toggle="onTopbarSearchToggle"
                @topbar-search-click="OnTopbarSearchClick"
                @topbar-search-hide="onTopbarSearchHide"
                class="sc-header"
            ></AppTopBar>

                <div class="sc-below-header">
                <AppMenu v-if="showMenu"/>
                <AppMobileMenu v-if="showMenu"></AppMobileMenu>
                <Unauthorized v-if="!authorized" />
                <router-view v-else/>
            </div>
        </div>

        <div class="layout-mask modal-in"></div>
        <EulaMessage />
    </div>
</template>

<script>
import AppMenu from './slots/AppMenu';
import { DomHandler } from 'primevue/utils';
import EventBus from './slots/event-bus';
import AppTopBar from './slots/AppTopbar';
import AppMobileMenu from './slots/AppMobileMenu';
import EulaMessage from '@/components/app/EulaMessage.vue';
import Unauthorized from '../pages/Unauthorized.vue';
export default {
    components: {
        AppTopBar,
        AppMobileMenu,
        AppMenu,
        EulaMessage,
        Unauthorized
    },
    props: {
        topbarTheme: {
            type: String,
            default: 'light',
        },
        menuTheme: {
            type: String,
            default: 'light',
        },
    },
    emits: ['change-color-scheme', 'change-component-theme', 'topbar-theme', 'menu-theme'],
    data() {
        return {
            menuActive: false,
            layoutMode: 'slim',
            colorScheme: 'light',
            sidebarActive: false,
            sidebarStatic: true,
            staticMenuDesktopInactive: false,
            staticMenuMobileActive: false,
            menuClick: false,
            searchActive: false,
            searchClick: false,
            topbarItemClick: false,
            activeTopbarItem: null,
            userMenuClick: false,
            topbarUserMenuActive: false,
            topbarUserMenuClick: false,
            topbarHelpMenuActive: false,
            topbarHelpMenuClick: false,
            rightMenuClick: false,
            rightMenuActive: false,
            configActive: false,
            configClick: false,
        };
    },
    computed: {
        containerClass() {
            return [
                'layout-wrapper',
                {
                    'layout-sidebar': this.layoutMode === 'sidebar',
                    'layout-static': this.layoutMode === 'sidebar' && this.sidebarStatic,
                    'layout-horizontal': this.layoutMode === 'horizontal',
                    'layout-slim': this.layoutMode === 'slim',
                    'layout-rightpanel-active': this.rightMenuActive,
                    'layout-mobile-active': this.staticMenuMobileActive,
                    'p-input-filled': this.$appState.inputStyle === 'filled',
                    'p-ripple-disabled': !this.$primevue.config.ripple,
                },
                'layout-menu-' + this.menuTheme + ' layout-topbar-' + this.topbarTheme,
            ];
        },
        organization() {
            return this.$store.app.organization;
        },
        authorized(){
            return this.$store.app.authorized === undefined || this.$store.app.authorized;
        },
        showMenu(){
            return this.$route.meta?.hideMenu !== true
        }
    },

    watch: {
        $route() {
            this.menuActive = false;
            this.$toast.removeGroup("messages");
            this.$store.app.authorized=true;
        },
    },
    methods: {
        onDocumentClick() {
            if (!this.searchClick && this.searchActive) {
                this.onTopbarSearchHide();
            }

            if (!this.topbarUserMenuClick && this.topbarUserMenuActive) {
                this.topbarUserMenuActive = false;
                this.topbarUserMenuClick = false;
            }

            if (!this.topbarHelpMenuClick && this.topbarHelpMenuActive) {
                this.topbarHelpMenuActive = false;
                this.topbarHelpMenuClick = false;
            }

            if (!this.rightMenuClick) {
                this.rightMenuActive = false;
            }

            if (!this.menuClick) {
                if (this.isSlim() || this.isHorizontal()) {
                    EventBus.emit('reset-active-index');
                    this.menuActive = false;
                }

                if (this.staticMenuMobileActive) {
                    this.hideOverlayMenu();
                }

                this.unblockBodyScroll();
            }

            if (this.configActive && !this.configClick) {
                this.configActive = false;
            }

            this.searchClick = false;
            this.configClick = false;
            this.userMenuClick = false;
            this.rightMenuClick = false;
            this.topbarUserMenuClick = false;
            this.topbarHelpMenuClick = false;
            this.menuClick = false;
        },
        onToggleMenu(event) {
            this.menuClick = true;
            this.sidebarStatic = !this.sidebarStatic;

            event.preventDefault();
        },
        onMenuClick(event) {
            if (this.isHorizontal() && DomHandler.hasClass(event.target, 'layout-menu-container')) {
                EventBus.emit('reset-active-index');
                this.menuClick = false;
                this.menuActive = false;
            }
            this.menuClick = true;
        },
        onMenuButtonClick(event) {
            this.menuClick = true;
            this.topbarUserMenuActive = false;
            this.topbarHelpMenuActive = false;
            this.rightMenuActive = false;

            if (this.isMobile()) {
                this.staticMenuMobileActive = !this.staticMenuMobileActive;
                if (this.staticMenuMobileActive) {
                    this.blockBodyScroll();
                } else {
                    this.unblockBodyScroll();
                }
            }

            event.preventDefault();
        },
        onMenuItemClick(event) {
            if (!event.item.items) {
                EventBus.emit('reset-active-index');
                this.hideOverlayMenu();
            }
            if (!event.item.items && this.isSlim()) {
                this.menuActive = false;
            }
        },
        onRootMenuItemClick() {
            this.menuActive = !this.menuActive;
        },
        onRightMenuClick() {
            this.rightMenuClick = true;
        },
        onRightMenuButtonClick(event) {
            this.rightMenuClick = true;
            this.rightMenuActive = !this.rightMenuActive;
            this.hideOverlayMenu();
            event.preventDefault();
        },
        onConfigClick() {
            this.configClick = true;
        },
        onConfigButtonClick() {
            this.configActive = !this.configActive;
            this.configClick = true;
        },
        hideOverlayMenu() {
            this.staticMenuMobileActive = false;
            this.unblockBodyScroll();
        },
        onChangeLayoutMode(mode) {
            this.layoutMode = mode;
            if (mode === 'sidebar') {
                if (this.sidebarStatic) {
                    this.sidebarActive = true;
                }
            } else {
                this.sidebarActive = false;

                if (this.topbarTheme !== this.menuTheme) {
                    this.$emit('menu-theme', this.topbarTheme);
                }
            }
        },
        onTopbarThemeChange(theme) {
            this.$emit('topbar-theme', theme);
        },
        onMenuThemeChange(theme) {
            this.$emit('menu-theme', theme);
        },
        changeComponentTheme(theme) {
            this.$emit('change-component-theme', theme);
        },
        changeColorScheme(scheme) {
            this.colorScheme = scheme;
            this.$emit('change-color-scheme', scheme);
            this.$appState.colorScheme = scheme;
        },
        onSidebarMouseOver() {
            this.sidebarActive = !this.isMobile();
        },
        onSidebarMouseLeave() {
            this.sidebarActive = false;
        },
        onTopbarSearchToggle() {
            this.searchActive = !this.searchActive;
            this.searchClick = true;
        },
        OnTopbarSearchClick() {
            this.searchClick = true;
        },
        onTopbarSearchHide() {
            this.searchActive = false;
            this.searchClick = false;
        },
        onTopbarUserMenuClick() {
            this.topbarUserMenuActive = !this.topbarUserMenuActive;
            this.topbarUserMenuClick = true;
        },
        onTopbarHelpMenuClick() {
            this.topbarHelpMenuActive = !this.topbarHelpMenuActive;
            this.topbarHelpMenuClick = true;
        },
        blockBodyScroll() {
            if (document.body.classList) {
                document.body.classList.add('blocked-scroll');
            } else {
                document.body.className += ' blocked-scroll';
            }
        },
        unblockBodyScroll() {
            if (document.body.classList) {
                document.body.classList.remove('blocked-scroll');
            } else {
                document.body.className = document.body.className.replace(new RegExp('(^|\\b)' + 'blocked-scroll'.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
            }
        },
        isSlim() {
            return this.layoutMode === 'slim';
        },
        isHorizontal() {
            return this.layoutMode === 'horizontal';
        },
        isDesktop() {
            return window.innerWidth > 991;
        },
        isMobile() {
            return window.innerWidth <= 991;
        },
    },
};
</script>

<style scoped lang="scss">
.layout-content-wrapper {
    display: flex;
    flex-direction: column;
}
.sc-below-header {
    height: calc(100vh - 64px);
    display: flex;
    flex-grow: 1;
}
</style>
