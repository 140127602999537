<template>
    <Sidebar ref="dialog" @hide="handleDialogClose" :showCloseIcon="false" :modal="true" position="right" v-model:visible="visible" class="p-sidebar-md sc-sidebar" style="width: 350px">
        <div class="sc-modal-wrapper">
            <div class="sc-modal-header p-d-flex p-ai-center">
                <div class="modal-title">Cluster Update Status</div>
            </div>

            <div class="sc-modal-body">
                <div v-if="!loaded">Loading...</div>
                <div v-if="loaded">
                    <div class="property-list">
                        <div class="property-item">
                            <div class="property-label">Cluster Name</div>
                            <div class="property-value">{{ cluster.data.name }}</div>
                        </div>
                        <div class="property-item p-mt-4">
                            <div class="property-label">Update Status</div>
                            <div class="property-value">
                                <Progress :value="updatePercent" :color="progressBarColor" />
                                <div class="hint p-mt-1 update-status-msg">{{ updateState }} {{ updateMessage }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="sc-modal-footer" >
                <div class="p-grid p-m-2">
                    <div class="p-col-12">
                        <SCButton label="Close" stretch @click="handleDialogClose()" />
                    </div>
                </div>
            </div>
        </div>
    </Sidebar>
</template>
<script>
import EventBus from '@/layouts/slots/event-bus';
import { cluster } from '@/lib/nucleus';
import Progress from '../shared/Progress.vue';
import { SCButton } from '@scale-computing/scale-design-framework';
import { rg4js } from '../../lib/raygun';
export default {
    components: { Progress, SCButton },
    data() {
        return {
            manualUUID: null,
            visible: false,
            loading: false,
            loaded: false,
            clusterId: null,
            cluster: null,
            interval: null,
        };
    },
    computed: {
        updatePercent() {
            return this.cluster.data.updatesStatus.percent;
        },
        updateState() {
            return this.cluster.data.updatesStatus.state;
        },
        updateMessage() {
            const message = this.cluster.data.updatesStatus.message;
            return message.length > 0 ? `- ${message}` : '';
        },
        progressBarColor() {
            let color = 'success';
            let state = this.updateState;
            if (state === 'FAILED' || state === 'TERMINATING') {
                color = 'error';
            } else if (state === 'PENDING') {
                color = '';
            }
            return color;
        },
    },
    mounted() {
        EventBus.on('show-cluster-update-status-modal', (payload) => {
            this.show(payload.clusterId);
        });
    },
    methods: {
        handleDialogClose() {
            this.visible = false;
            clearInterval(this.interval);
        },
        show(clusterId) {
            this.clusterId = clusterId;
            this.visible = true;
            this.load();

            this.interval = setInterval(() => {
                this.load();
            }, 10000);
        },
        async load() {
            try {
                this.loading = true;
                const response = await cluster.getById2({ id: this.clusterId });
                this.cluster = response;
                this.loaded = true;
            } catch (e) {
                rg4js('send', { error: e});
                this.$toast.add({ group: "messages",  severity: 'error', summary: `Unable to load cluster`, detail: e.message });
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>


<style lang="scss">
.scale-no-release-notes {
    font-size: 14px;
}

.sc-sidebar {

    .p-sidebar-content {

        .sc-modal-wrapper {
            display: flex;
            flex-direction: column;
            width: 100%;

            .sc-modal-header {
                padding: 1em;
                font-weight: 600;
                color: #131314;
                font-size: 18px;
                border-bottom: 1px solid #8f90a6;
            }

            .sc-modal-body {
                padding: 1rem;
                flex: 1;
                display: flex;
                flex-direction: column;
                overflow-y: scroll;
            }

            .sc-modal-footer {
                border-top: 1px solid #8f90a6;
            }
        }
    }
}

</style>
